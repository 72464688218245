<template>
  <base-section
    class="secondary pa-4"
  >
    <v-responsive
      class="mx-auto"
    >
      <v-container fluid>
        <v-row class="justify-center">
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  import { mapState } from 'vuex'
  import { STORE_NAMESPACE_REALIZATIONS } from '@/views/realizations/Index.vue'

  export default {
    name: 'SectionRezlizationsFeatures',
    computed: {
      ...mapState(STORE_NAMESPACE_REALIZATIONS, [
        'translations',
      ]),
      cards () {
        return [
          {
            icon: 'mdi-cog',
            title: this.translations?.realizationsTitle1,
            scrollId: 'realizationsGallery1',
          },
          {
            icon: 'mdi-factory',
            title: this.translations?.realizationsTitle2,
            scrollId: 'realizationsGallery2',
          },
          {
            icon: 'mdi-hammer-wrench',
            title: this.translations?.realizationsTitle3,
            scrollId: 'realizationsGallery3',
          },
        ]
      },
    },
  }
</script>
